import { Box } from "@chakra-ui/react";
import React, { createRef, useLayoutEffect } from "react";

const src = "https://giscus.app/client.js";

export interface IUtterancesProps {
  repo: string;
}

const Utterances: React.FC<IUtterancesProps> = React.memo(({ repo }) => {
  const containerRef = createRef<HTMLDivElement>();

  useLayoutEffect(() => {
    const utterances = document.createElement("script");

    const attributes = {
      src,
      "data-repo": repo,
      "issue-term": "pathname",
      "data-repo-id": "R_kgDOGsuYIA",
      "data-category": "Announcements",
      "data-category-id": "DIC_kwDOGsuYIM4CPk9V",
      "data-mapping": "pathname",
      "data-reactions-enabled": "1",
      "data-emit-metadata": "0",
      "data-input-position": "top",
      "data-theme": "light",
      "data-lang": "ko",
      crossOrigin: "anonymous",
      async: "true",
    };

    Object.entries(attributes).forEach(([key, value]) => {
      utterances.setAttribute(key, value);
    });

    containerRef.current?.appendChild(utterances);
  }, [repo]);

  return <Box mt="24px" ref={containerRef} />;
});

Utterances.displayName = "Giscus";

export default Utterances;
